import React, { useEffect, useState } from 'react';

const SavedPlans = () => {
    const [selectedCategory, setSelectedCategory] = useState("diets");
    const [savedPlans, setSavedPlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // State for error handling

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const getUserSavedData = async (sessionObj, saved_type) => {
        try {
            console.log("Fetching user saved data...");
            setLoading(true);
            setError(null); // Reset error state before fetching
            const get_data = saved_type === "diets" ? "saved_diets" : "saved_workouts";

            const response = await fetch('https://ygcptpjdpipejtvnjnlg.supabase.co/functions/v1/get_saved_list', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionObj['access_token']}`
                },
                body: JSON.stringify({ table: get_data })
            });

            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('No saved plans found.');
                } else {
                    throw new Error(`Error ${response.status}: ${response.statusText}`);
                }
            }

            const data = await response.json();
            console.log('Successfully retrieved user saved data:', data);
            
            // Ensure data is an array
            if (Array.isArray(data)) {
                setSavedPlans(data);
            } else {
                console.warn('Expected data to be an array, but received:', data);
                setSavedPlans([]);
                setError('Received unexpected data format from server.');
            }
        } catch (error) {
            console.error('Error fetching saved data:', error);
            setSavedPlans([]); // Reset savedPlans on error
            if (error.message === 'No saved plans found.') {
                setError('No saved plans found.');
            } else {
                setError('Failed to fetch saved plans. Please try again later.');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Fetch saved diets and workouts from the database
        const sessionString = localStorage.getItem('sb-ygcptpjdpipejtvnjnlg-auth-token');
        if (sessionString) {
            try {
                const sessionObj = JSON.parse(sessionString);
                getUserSavedData(sessionObj, selectedCategory); // Fetch data based on the selected category
            } catch (parseError) {
                console.error('Error parsing session data:', parseError);
                setLoading(false);
                setError('Invalid session data. Please log in again.');
            }
        } else {
            setLoading(false); // No session found, stop loading
            setError('No active session found. Please log in.');
        }
    }, [selectedCategory]); // Re-fetch data when selectedCategory changes

    // Helper function to group workouts by day
    const groupWorkoutsByDay = (workouts) => {
        if (!Array.isArray(workouts)) {
            console.warn("workouts is not an array:", workouts);
            return {};
        }
        return workouts.reduce((acc, workout) => {
            const day = workout.Day;
            if (!day) {
                console.warn("Workout item missing 'Day' property:", workout);
                return acc;
            }
            if (!acc[day]) {
                acc[day] = [];
            }
            acc[day].push(workout);
            return acc;
        }, {});
    };

    const renderDietPlanSummary = (plan, index) => (
        <div key={plan.id || index} className="bg-gray-800 p-6 rounded-lg shadow-lg mb-4">
            <h2 className="text-2xl font-semibold text-white text-center mb-2">
                {`Diet Plan #${index + 1}`}
            </h2>
            
            <p className="text-gray-400 text-sm text-center mb-4">
                Created: {new Date(plan.created_at).toLocaleDateString()} {new Date(plan.created_at).toLocaleTimeString()}
            </p>

            {plan.diet_id && (
                <button
                    onClick={() => window.location.href = `https://www.getfitter.ai/fulldiet/${plan.diet_id}`}
                    className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors mb-4"
                >
                    Show Full Diet Plan
                </button>
            )}

            <div className="bg-gray-700 rounded-lg p-4">
                {plan.diet_one?.length > 0 ? (
                    <div>
                        <p className="text-gray-400 text-sm mb-2">
                            {`${plan.diet_one.length} meals in this plan`}
                        </p>
                        {plan.diet_one.slice(0, 3).map((item) => (
                            <div key={item.id} className="text-white mb-2">
                                <span className="font-medium">{item.Meal}:</span> {item.Item} - {item.Calories} Calories
                            </div>
                        ))}
                        {plan.diet_one.length > 3 && (
                            <p className="text-gray-400 text-sm text-center italic mt-2">
                                ... and {plan.diet_one.length - 3} more meals
                            </p>
                        )}
                    </div>
                ) : (
                    <p className="text-gray-400 text-center">No diet items available.</p>
                )}
            </div>
        </div>
    );

    const renderWorkoutPlanSummary = (plan, index) => (
        <div key={plan.id || index} className="bg-gray-800 p-6 rounded-lg shadow-lg mb-4">
            <h2 className="text-2xl font-semibold text-white text-center mb-2">
                {`Workout Plan #${index + 1}`}
            </h2>

            <p className="text-gray-400 text-sm text-center mb-4">
                Created: {new Date(plan.created_at).toLocaleDateString()} {new Date(plan.created_at).toLocaleTimeString()}
            </p>

            {plan.workout_id && (
                <button
                    onClick={() => window.location.href = `https://www.getfitter.ai/fullworkout/${plan.workout_id}`}
                    className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors mb-4"
                >
                    Show Full Workout Plan
                </button>
            )}

            <div className="bg-gray-700 rounded-lg p-4">
                {plan.workout_plan?.length > 0 ? (
                    <div>
                        <p className="text-gray-400 text-sm mb-2">
                            {`${plan.workout_plan.length} exercises in this plan`}
                        </p>
                        {plan.workout_plan.slice(0, 3).map((workout) => (
                            <div key={workout.id} className="text-white mb-2">
                                <span className="font-medium">{workout["Workout Name"]}</span>
                                {workout["Body Part Targeted"] && ` - ${workout["Body Part Targeted"]}`}
                            </div>
                        ))}
                        {plan.workout_plan.length > 3 && (
                            <p className="text-gray-400 text-sm text-center italic mt-2">
                                ... and {plan.workout_plan.length - 3} more exercises
                            </p>
                        )}
                    </div>
                ) : (
                    <p className="text-gray-400 text-center">No workout items available.</p>
                )}
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-gray-900 p-4">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl font-bold text-white text-center mb-8">
                    Saved Plans
                </h1>

                {/* Category Selector */}
                <div className="bg-gray-800 p-2 rounded-lg mb-6 flex">
                    <button
                        onClick={() => setSelectedCategory("diets")}
                        className={`flex-1 py-3 px-4 rounded-md text-center transition-colors ${
                            selectedCategory === "diets"
                                ? "bg-blue-600 text-white"
                                : "text-gray-400"
                        }`}
                    >
                        Diets
                    </button>
                    <button
                        onClick={() => setSelectedCategory("workouts")}
                        className={`flex-1 py-3 px-4 rounded-md text-center transition-colors ${
                            selectedCategory === "workouts"
                                ? "bg-blue-600 text-white"
                                : "text-gray-400"
                        }`}
                    >
                        Workouts
                    </button>
                </div>

                {/* Error Message */}
                {error && (
                    <div className="bg-red-600 text-white p-4 rounded-lg mb-6 text-center">
                        {error}
                    </div>
                )}

                {/* Loading State */}
                {loading ? (
                    <div className="flex items-center justify-center space-x-2 p-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
                        <span className="text-white">Fetching your saved plans...</span>
                    </div>
                ) : savedPlans.length > 0 ? (
                    savedPlans.map((plan, index) => (
                        selectedCategory === "diets"
                            ? renderDietPlanSummary(plan, index)
                            : renderWorkoutPlanSummary(plan, index)
                    ))
                ) : (
                    <div className="bg-gray-800 p-8 rounded-lg text-center">
                        <p className="text-gray-400 text-lg">
                            {selectedCategory === "diets"
                                ? "No saved diet plans found. Start by saving your favorite diets!"
                                : "No saved workout plans found. Start by saving your favorite workouts!"}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SavedPlans;
